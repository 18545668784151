<template>
  <div v-if="flag">
    <div id="nosotros">
      <v-container class="py-0">
        <v-row style="height: calc(100vh - 64px)" align="start" align-sm="center">
          <v-col cols="2" class="py-0">
            <div class="d-none d-sm-block">
              <v-img src="../assets/img/FINK-VERTICAL.svg" height="80vh" :contain="true" class="logo-vertical" v-bind:class="{'mostrar':showLogo}"></v-img>
            </div>
            <div class="d-sm-none">
              <br>
              <v-img src="../assets/img/FINK-VERTICAL.svg" height="200px" :contain="true"></v-img>
            </div>
          </v-col>
          <v-col cols="10" class="pl-sm-12" align-self="center">
            <v-row>
              <v-col>
                <v-carousel id="carousel-top" height="50vh" cycle continuous hide-delimiters :show-arrows="false">
                  <template v-for="(image, i) in homeSingleton.Bloque1Carrusel">
                    <v-carousel-item reverse-transition="fade-transition" transition="fade-transition" :key="'images-car'+i">
                      <v-img :src="'https://finkcms.actstudio.xyz/'+image.path" width="100%" height="100%" eager></v-img>
                    </v-carousel-item>
                  </template>
                </v-carousel>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" sm="6" class="py-0 py-sm-3">
                <h1>{{homeSingleton.Bloque1Titulo}}</h1>
              </v-col>
              <v-col cols="12" sm="6" class="py-0 py-sm-3">
                <p style="max-width: 500px">{{homeSingleton.Bloque1Descripcion}}</p>
              </v-col>
            </v-row>
            <!-- <v-row class="d-none d-sm-block mt-xl-12">
              <v-col class="text-center py-0">
                <template v-for="(marca, m) in homeSingleton.Bloque1MarcasFila1">
                  <a :href="marca.meta.title" :key="'marca-'+m" target="_blank">
                    <v-img :src="'https://finkcms.actstudio.xyz/'+marca.path"  :max-height="firstLogoHeight" :max-width="firstLogoWidth" class="d-inline-block" contain></v-img>
                  </a>
                </template>
              </v-col>
            </v-row>
            <v-row class="d-none d-sm-block mt-xl-12">
              <v-col class="text-center py-0">
                <template v-for="(marca, m) in homeSingleton.Bloque1MarcasFila2">
                  <a :href="marca.meta.title" :key="'marca-2-'+m" target="_blank">
                    <v-img :src="'https://finkcms.actstudio.xyz/'+marca.path" :key="'marca-'+m" :max-height="firstLogoHeight" :max-width="firstLogoWidth" class="d-inline-block" contain></v-img>
                  </a>
                </template>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
      </v-container>

      <div class="">
        <v-container>
          <v-row align="center">
            <template v-for="(marca, m) in homeSingleton.Bloque1MarcasFila1">
              <v-col cols="4" sm="3" :key="'marca-mm-'+m">
                <a :href="marca.meta.title" target="_blank">
                  <v-img :src="'https://finkcms.actstudio.xyz/'+marca.path"  class="d-inline-block" contain position="center center"></v-img>
                </a>
              </v-col>
            </template>

            <template v-for="(marca, m) in homeSingleton.Bloque1MarcasFila2">
              <v-col cols="4" sm="3" :key="'marca-mma-'+m">
                <a :href="marca.meta.title" target="_blank">
                  <v-img :src="'https://finkcms.actstudio.xyz/'+marca.path"  class="d-inline-block" contain position="center center"></v-img>
                </a>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </div>
    

      <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque2FondoDesktop.path" height="400" contain position="calc(100% + 80px)" class="align-end d-sm-none imagen-movil-nuestro-valor">
        <v-container>
          <v-row justify-sm="center">
            <v-col cols="7">
              <h2>{{homeSingleton.Bloque2Titulo}}</h2>
              <h3 class="break-lines not-break-lines-xs mt-4">{{homeSingleton.Bloque2Descripcion}}</h3>
              <br><br><br>
            </v-col>
          </v-row>
        </v-container>
      </v-img>

      <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque2FondoDesktop.path" height="90vh" contain position="right 30px" sizes="500px" class="align-center d-none d-sm-flex">
        <v-container>
          <v-row justify-sm="center">
            <v-col cols="9">
              <h2>{{homeSingleton.Bloque2Titulo}}</h2>
              <h3 class="break-lines mt-4">{{homeSingleton.Bloque2Descripcion}}</h3>
            </v-col>
          </v-row>
        </v-container>
      </v-img>

      <div class="my-12">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="9" xl="7">
              <v-img id="imagen-nuestro-valor" :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque2ImagenSecundaria.path" max-width="540px" eager></v-img>
              <v-card width="570px" class="pa-12 ml-auto" color="#efe96d" elevation="0" tile>
                <h1>{{homeSingleton.Bloque2TituloSecundario}}</h1>
                <p>{{homeSingleton.Bloque2DescripcionSecundaria}}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div> <!-- Cierra #nosotros -->

    <div id="filosofia" style="margin-top: 130px">  
      <v-container>
        <v-row>
          <v-col cols="12" md="7" xl="6" class="text-center text-sm-left">
            <h2>{{homeSingleton.Bloque3Titulo}}</h2>
            <h5 class="break-lines mt-3 mt-sm-0">{{homeSingleton.Bloque3Subtitulo}}</h5>
            <p>{{homeSingleton.Bloque3Descripcion}}</p>

            <v-img id="filosofia-libros" :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque3ImagenParallax.path" width="350px" max-width="100%" aspect-ratio="1" class="mr-auto ml-sm-auto mr-sm-0" ></v-img>
            
          </v-col>
          <v-col cols="12" md="5" xl="6">
            <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque3ImagenFija.path" height="530px" class="d-none d-sm-flex"></v-img>

            <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque3ImagenFija.path" width="80%" aspect-ratio="1.3" class="d-sm-none ml-auto"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div> <!-- Cierra #filosofia-->

    <div class="mt-12">
      <div id="empresas">
        <v-container>
          <v-row no-gutters>
            <v-col class="text-center text-sm-left">
              <h2 class="titulo-nuestras-empresas">{{homeSingleton.Bloque4Titulo}}</h2>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="5" class="pr-12" order="1" order-xl="0">
              <a :href="homeSingleton.Bloque4Empresa1Link" target="_blank">
                <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa1Logo.path" width="100%" contain position="left center"></v-img>
              </a>
              <b>{{homeSingleton.Bloque4Empresa1Slogan}}</b>
              <p>{{homeSingleton.Bloque4Empresa1Descripcion}}</p>
            </v-col>
            <v-col cols="12" md="7" order="0" order-sm="1" class="px-0 px-sm-3">
              <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa1Imagen.path" width="100%" contain class="d-sm-none"></v-img>

              <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa1Imagen.path" height="700px" max-height="80vh" class="d-none d-sm-flex"></v-img>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid>
          <!-- <v-row id="alis-foods-container" align="center" align-sm="center">
            <v-col cols="12" sm="6" class="px-0 pr-sm-12">
              <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa2Imagen.path" class="align-end px-sm-3 d-none d-sm-flex" eager>
              </v-img>

              <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa2ImagenMovil.path" class="align-end px-sm-3 alis-foods-image-background d-sm-none">
                <a :href="homeSingleton.Bloque4Empresa2Link" target="_blank" class="d-sm-none">
                  <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa2Logo.path" width="160px"></v-img>
                </a>
              </v-img>
            </v-col>
            <v-col cols="12" sm="6">
              <a :href="homeSingleton.Bloque4Empresa2Link" target="_blank" class="d-none d-sm-inline">
                <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa2Logo.path" width="160px"></v-img>
              </a>
              <b class="mt-12 d-block">{{homeSingleton.Bloque4Empresa2Slogan}}</b>
              <p class="break-lines not-break-lines-xs">{{homeSingleton.Bloque4Empresa2Descripcion}}</p>

              <v-row justify="center">
                <template v-for="(logo,l) in homeSingleton.Bloque4Empresa2Logos">
                  <v-col :key="'logo-alis-'+l" cols="4">
                    <v-img :src="'https://finkcms.actstudio.xyz/'+logo.path"></v-img>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row> -->

          <!-- <v-container>
            <v-row align="center" align-sm="center" justify="center">
              <v-col cols="12" sm="6" md="5" class="text-center my-6 my-sm-3">
                <a :href="homeSingleton.Bloque4Empresa3Link" target="_blank">
                  <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa3Logo.path" width="100%"></v-img>
                </a>
                <b class="mt-3 mt-sm-0 d-block">{{homeSingleton.Bloque4Empresa3Slogan}}</b>
                <p class="break-lines not-break-lines-xs">{{homeSingleton.Bloque4Empresa3Descripcion}}</p>
                <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa3Imagen.path" class="align-end d-sm-none" eager aspect-ratio="1">
                </v-img>
              </v-col>
              <v-col cols="12" sm="6" offset-md="1" class="px-0">
                <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa3Imagen.path" class="align-end px-sm-3 d-none d-sm-flex" eager>
                </v-img>
              </v-col>
            </v-row>
          </v-container> -->


          <v-row align="center" align-sm="center" class="my-6 my-sm-12">
            <v-col cols="12" sm="6" class="px-0 pr-sm-12">
              <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa4Imagen.path" class="align-end px-sm-3 d-none d-sm-flex" eager>
              </v-img>
            </v-col>
            <v-col cols="12" sm="6" class="text-center text-sm-left">
              <a :href="homeSingleton.Bloque4Empresa4Link" target="_blank" class="d-sm-inline">
                <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa4Logo.path" max-width="400px" class="mx-auto mx-sm-0"></v-img>
              </a>
              <b class="d-block">{{homeSingleton.Bloque4Empresa4Slogan}}</b>
              <p class="break-lines not-break-lines-xs">{{homeSingleton.Bloque4Empresa4Descripcion}}</p>
              <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque4Empresa4Imagen.path" class="align-end px-sm-3 d-sm-none" eager>
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </div> <!-- Cierra #filosofia-->

      <v-container id="ventures">
        <v-row>
          <v-col class="px-sm-3">
            <v-card tile color="#f2f1f0" class="pa-sm-12" elevation="0">
              <div class="px-3 pa-sm-12">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque5Logo.path" max-height="142px" contain position="left" class="mx-auto"></v-img>
                    <h3>{{homeSingleton.Bloque5Subtitulo}}</h3>
                    <p class="break-lines not-break-lines-xs">{{homeSingleton.Bloque5Descripcion}}</p>

                    <v-row>
                      <template v-for="(logoVentures, lv) in homeSingleton.Bloque5Logos">
                        <v-col cols="4" :key="'logo-ventures-'+lv">
                          <v-img :src="'https://finkcms.actstudio.xyz/'+logoVentures.path"></v-img>
                        </v-col>
                      </template>
                    </v-row>
                    <div class="text-center">
                      

                      <v-dialog
                        v-model="dialog"
                        max-width="900px"
                      >
                        <template v-slot:activator="{ on, attrs }">

                          <v-btn text tile v-bind="attrs"
                            v-on="on"><span class="not-uppercase">{{ $t('nuestras-marcas-label') }}</span></v-btn>
                        </template>

                        <v-card color="#efe96d">
                          <v-card-title class="text-h5">
                            {{$t('nuestras-marcas-label')}}
                          </v-card-title>

                          <v-card-text>
                            <div class="ventures-container" v-html="homeSingleton.Bloque5Ventures">

                            </div>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              @click="dialog = false"
                            >
                              Cerrar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-0">
                    <v-img id="foto-pool" :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque5Imagen.path" class="d-none d-sm-flex"></v-img>

                    <v-row class="d-sm-none">
                      <v-col cols="4">
                        
                      </v-col>
                      <v-col cols="8">
                        <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque5Imagen.path" class="d-sm-none" style="transform: translateY(50px)"></v-img>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container> <!-- Cierra #filosofia-->

      <div id="team" class="my-12 py-12">
        <v-container>
          <v-row>
            <v-col class="text-center">
              <h2>{{homeSingleton.Bloque6Titulo}}</h2>
              <h4 class="break-lines mt-3">{{homeSingleton.Bloque6Subtitulo}}</h4>
              <p class="mt-6" style="font-size: 22px">{{homeSingleton.Bloque6Descripcion}}</p>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" sm="12" class="text-center">
              <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque6Imagen.path" class="mx-auto" position="center"></v-img>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12" sm="7" class="text-center text-sm-left">
                  <p class="" style="font-size: 22px">{{homeSingleton.Bloque6Descripcion2}}</p>
                </v-col>
                <v-col cols="12" sm="5" class="text-center">
                  <v-img id="team-second-image" :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque6Imagen2.path" class="mx-auto d-none d-sm-flex" :contain="false" height="150" width="50%" aspect-ratio="16/9" style=""></v-img>

                  <v-img :src="'https://finkcms.actstudio.xyz/'+homeSingleton.Bloque6Imagen2.path" class="mx-auto d-sm-none" height="250px"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div> <!-- Cierra #team-->
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Home',
    data() {
      return {
        homeEs: {},
        homeEn: {},
        homeSingleton: {},
        flag: false,
        showLogo: true,
        dialog: false
      }
    },
    components: {
    },
    computed: {
      firstLogoHeight: function () {
        if(this.$vuetify.breakpoint.xs){
          return 45;
        }

        if(this.$vuetify.breakpoint.sm){
          return 30;
        }

        if(this.$vuetify.breakpoint.md){
          return 45;
        }

        if(this.$vuetify.breakpoint.lg){
          return 55;
        }

        if(this.$vuetify.breakpoint.xl){
          return 80;
        }
      },
      firstLogoWidth: function () {
        if(this.$vuetify.breakpoint.xs){
          return 80;
        }

        if(this.$vuetify.breakpoint.sm){
          return 60;
        }

        if(this.$vuetify.breakpoint.md){
          return 80;
        }

        if(this.$vuetify.breakpoint.lg){
          return 100;
        }

        if(this.$vuetify.breakpoint.xl){
          return 170;
        }
      },
      lang: function () {
        return this.$i18n.locale
      }
    },
    watch: {
      lang: function () {
        this.$vuetify.goTo(0);
        if(this.lang == 'en'){
          this.homeSingleton = this.homeEn;
        }else{
          this.homeSingleton = this.homeEs;
        }
      }
    },
    beforeMount: function(){
      var aux = this
      fetch('https://finkcms.actstudio.xyz/api/singletons/get/Home')
      .then(data => data.json())
      .then((data) => {
        this.homeEs = data
        this.homeSingleton = data
        this.flag = true

        
        fetch('https://finkcms.actstudio.xyz/api/singletons/get/Home', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                lang: 'en' // return normalized language fields (fieldA_de => fieldA)
            })
        })
        .then(data => data.json())
        .then((data) => {
          aux.homeEn = data
        });
      });


      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll: function () {
        var aId = document.getElementById("app-id");

        var posicion = aId.getBoundingClientRect().y;

        if(posicion > -100){
          this.$emit('showLogo')
          this.showLogo = true
        }else{
          this.$emit('hideLogo')
          this.showLogo = false
        }



        var x = document.getElementById("imagen-nuestro-valor");

        posicion = x.getBoundingClientRect().y * .1;

        posicion = posicion + 110;

        if(this.$vuetify.breakpoint.width >= 600){
          x.style.transform = 'translateY('+posicion.toString()+'px)';
        }else{
          x.style.transform = '';
        }


        var z = document.getElementById("filosofia-libros");

        posicion = z.getBoundingClientRect().y * .1;



        if(this.$vuetify.breakpoint.width >= 600){
          posicion = posicion - 30;
          z.style.transform = 'translateX(50px) translateY('+posicion.toString()+'px)'
        }else{
          posicion = posicion - 60;
          posicion = posicion * -1;
          z.style.transform = 'translateY('+posicion.toString()+'px)'
        }

        var n = document.getElementById("foto-pool");

        posicion = n.getBoundingClientRect().y * .2;

        n.style.transform = 'translateY('+posicion.toString()+'px) scale(1.05) translateX(100px)';


        var t = document.getElementById("team-second-image");

        posicion = t.getBoundingClientRect().y * .05;

        t.style.transform = 'translateY('+posicion.toString()+'px) translateX(-10px) scale(2)';



        var n = document.getElementById("team");

        posicion = n.getBoundingClientRect().y;

        if(posicion > 0){
          this.$emit('show',1)
        }else{
          this.$emit('disable',0)
        }

      }
    }
  }
</script>
