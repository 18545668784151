<template>
  <v-app id="app-id">
    <v-system-bar
      color="#efe96d"
      app
      :height="systemBarHeight"
      v-if="systemBarHeight > 0"
    >
      <v-container>
        <v-row>
          <v-col>
            <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="showSystemBar = false; $vuetify.goTo('#nosotros')">{{ $t('nav.Nosotros') }}</v-btn>
            <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="showSystemBar = false; $vuetify.goTo('#filosofia')">{{ $t('nav.Filosofia') }}</v-btn>
            <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="showSystemBar = false; $vuetify.goTo('#empresas')">{{ $t('nav.Empresas') }}</v-btn>
            <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="showSystemBar = false; $vuetify.goTo('#ventures')">{{ $t('nav.Ventures') }}</v-btn>
            <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="showSystemBar = false; $vuetify.goTo('#team')">{{ $t('nav.Equipo') }}</v-btn>
            <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="showSystemBar = false; dialog = true">{{ $t('nav.Contacto') }}</v-btn>
            <div class="d-md-none text-center">
              <v-btn text small @click="$i18n.locale = 'es'; showSystemBar = false">ES</v-btn> | <v-btn text small @click="$i18n.locale = 'en'; showSystemBar = false">EN</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-system-bar>
    <v-app-bar
      app
      color="white"
      elevation="0"
      v-model="showingMenu"
      class="px-3"
    >
      <div>
        <v-img src="@/assets/img/logos/fink-amarillo.svg" width="150" class="header-logo" v-bind:class="{'mostrar': !showingLogo}"></v-img>
      </div>
      <v-spacer></v-spacer>
      <div class="d-none d-md-block">
        <v-btn text :ripple="false" class="header-item-link" @click="$vuetify.goTo('#nosotros')">{{ $t('nav.Nosotros') }}</v-btn>
        <v-btn text :ripple="false" class="header-item-link" @click="$vuetify.goTo('#filosofia')">{{ $t('nav.Filosofia') }}</v-btn>
        <v-btn text :ripple="false" class="header-item-link" @click="$vuetify.goTo('#empresas')">{{ $t('nav.Empresas') }}</v-btn>
        <v-btn text :ripple="false" class="header-item-link" @click="$vuetify.goTo('#ventures')">{{ $t('nav.Ventures') }}</v-btn>
        <v-btn text :ripple="false" class="header-item-link" @click="$vuetify.goTo('#team')">{{ $t('nav.Equipo') }}</v-btn>
        <v-btn text :ripple="false" class="header-item-link" @click="dialog = true">{{ $t('nav.Contacto') }}</v-btn>

        <v-btn text small @click="$i18n.locale = 'es'">ES</v-btn> | <v-btn text small @click="$i18n.locale = 'en'">EN</v-btn>
      </div>
      <div class="d-sm-none text-right">
        <v-btn icon class="mx-0 px-0" @click="showSystemBar = !showSystemBar"><v-icon>mdi-menu</v-icon></v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view @show="showMenu()" @disable="disableMenu()" @showLogo="showLogo()" @hideLogo="hideLogo()" />
    </v-main>

    <v-footer class="pa-0">
      <v-card
        flat
        tile
        width="100%"
        class="pt-6" 
        color="#efe96d"
      >
        <v-card-text class="pb-12">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4">
                <v-img src="@/assets/img/logos/fink.svg" max-width="310" class="d-none d-sm-flex"></v-img>

                <v-img src="@/assets/img/logos/fink.svg" max-width="210" class="d-sm-none mx-auto"></v-img>
              </v-col>
              <v-col class="text-md-right">
                <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="$vuetify.goTo('#nosotros')">{{ $t('nav.Nosotros') }}</v-btn>
                <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="$vuetify.goTo('#filosofia')">{{ $t('nav.Filosofia') }}</v-btn>
                <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="$vuetify.goTo('#empresas')">{{ $t('nav.Empresas') }}</v-btn>
                <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="$vuetify.goTo('#ventures')">{{ $t('nav.Ventures') }}</v-btn>
                <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="$vuetify.goTo('#team')">{{ $t('nav.Equipo') }}</v-btn>
                <v-btn text :ripple="false" class="header-item-link grey--text d-block d-sm-inline-block mx-auto" @click="dialog = true">{{ $t('nav.Contacto') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-container fluid>
          <v-row>
            <v-col class="px-3">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-container>


        <v-card-text class="grey--text">
          <v-container fluid>
            <v-row>
              <v-col>
                Copyright © Fink Services S.A. de C.V. all rights reserved
                {{ new Date().getFullYear() }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-footer>

    <v-dialog
      v-model="dialog"
      max-width="800"
    >

      <v-card color="#efe96d">
        <v-card-title class="text-center">
          <v-row>
            <v-col offset="1" cols="10">
              <h3>Contacto</h3>
            </v-col>
            <v-col cols="1" class="text-right px-0">
              <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="name"
              :rules="requiredRules"
              label="Nombre"
              required
              color="black"
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
              color="black"
            ></v-text-field>

            <v-textarea
              name="input-7-1"
              label="Comentario"
              color="black"
              :rules="requiredRules"
              v-model="comment"
            ></v-textarea>

          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="sendComment()"
            :disabled="!valid"
          >
            Enviar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
    >
      ¡Gracias!
    </v-snackbar>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    showingMenu: true,
    showingLogo: true,
    dialog: false,
    valid: true,
    name: '',
    requiredRules: [
      v => !!v || '',
    ],
    email: '',
    emailRules: [
      v => !!v || '',
      v => /.+@.+\..+/.test(v) || '',
    ],
    comment: '',
    snackbar: false,
    showSystemBar: false
  }),
  beforeMount: function(){
  },
  computed: {
    systemBarHeight: function (){
      if(this.$vuetify.breakpoint.xs && this.showSystemBar){
        return 300
      }else{
        return 0
      }
    }
  },
  methods: {
    showMenu: function (){
      this.showingMenu = true
    },
    disableMenu: function (){
      this.showingMenu = false
    },
    showLogo: function(){
      this.showingLogo = true;
    },
    hideLogo: function(){
      this.showingLogo = false;
    },
    sendComment: function (){
      if(this.$refs.form.validate()){
        var data = {
          Name: this.name,
          Mail: this.email,
          Comment: this.comment
        };

        //4ee70a03ab1a2b45ed886cb5a68420

        fetch('https://finkcms.actstudio.xyz/api/forms/submit/Contacto?token=4ee70a03ab1a2b45ed886cb5a68420', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                form: data
            })
        })
        .then(entry => entry.json());

        this.dialog = false;
        this.snackbar = true;

        this.name = '';
        this.email = '';
        this.comment = '';
      }
    }
  }
};
</script>
